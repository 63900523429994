import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { LISTING_STATE_CLOSED, LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  PaginationLinks,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LandingFooter,
  NamedLink,
  IconSpinner,
} from '../../components';
import { TopbarContainer } from '..';
import {
  closeListing,
  discardListing,
  manualLoadData,
  openListing,
} from './MyWantedPostsPage.duck';
import {
  createSlug,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import {
  formatTitle,
  MAX_LENGTH_FOR_WORDS_IN_TITLE,
} from '../../components/ManageListingCard/ManageListingCard';
import { types as sdkTypes } from '../../util/sdkLoader';
import classNames from 'classnames';
import Overlay from '../../components/ManageListingCard/Overlay';
import { formatDateForWantedPosts } from '../../util/dates';
import { LISTING_TYPE, DEFAULT_TITLE } from '../../constants';
import { requestCreateListingDraft } from '../../containers/EditListingPage/EditListingPage.duck';
import { withRouter } from 'react-router-dom';

import css from './MyWantedPostsPage.module.css';

const { Money, UUID } = sdkTypes;

const pricingType = {
  hourly: 'MyWantedPostsPage.hour',
  daily: 'MyWantedPostsPage.day',
  weekly: 'MyWantedPostsPage.week',
};

const MyWantedPostsPageComponent = props => {
  const {
    currentUser,
    isAuthenticated,
    listings,
    pagination,
    queryParams,
    queryInProgress,
    queryListingsError,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    discardingListing,
    discardingListingError,
    onGetMyWantedPosts,
    onCreateNewListing,
    onOpenListing,
    onCloseListing,
    onDiscardListing,
    intl,
    history,
    scrollingDisabled,
  } = props;

  const hasPaginationInfo = useMemo(() => !!pagination && pagination.totalItems != null, [
    pagination,
  ]);
  const listingsAreLoaded = useMemo(() => !queryInProgress && hasPaginationInfo, [
    hasPaginationInfo,
    queryInProgress,
  ]);

  const notAvailable = useMemo(() => intl.formatMessage({ id: 'MyWantedPostsPage.notAvailable' }), [
    intl,
  ]);

  const loadingResults = useMemo(
    () => (
      <h2>
        <FormattedMessage id="MyWantedPostsPage.loadingOwnListings" />
      </h2>
    ),
    []
  );

  const queryError = useMemo(
    () => (
      <h2 className={css.error}>
        <FormattedMessage id="MyWantedPostsPage.queryError" />
      </h2>
    ),
    []
  );

  const createWantedLink = useMemo(
    () => (
      <span
        onClick={() =>
          onCreateNewListing({
            title: DEFAULT_TITLE,
            publicData: {
              listingType: LISTING_TYPE.WANTED_POST,
            },
          }).then(res => {
            const { uuid } = res.data.data.id;
            const slug = createSlug(res.data.data.attributes.title);
            history.push(`/l/${slug}/${uuid}/draft/description`, {
              claimedItemId: currentUser?.id?.uuid,
            });
          })
        }
      >
        <span className={css.button}>
          <FormattedMessage id="WantedPage.createWantedPost" />
        </span>
      </span>
    ),
    [currentUser?.id?.uuid, history, onCreateNewListing]
  );

  const noResults = useMemo(
    () =>
      listingsAreLoaded && pagination.totalItems === 0 ? (
        <div className={css.titleContainer}>
          <h1>
            <FormattedMessage id="MyWantedPostsPage.noResults" />
          </h1>
          <h3>
            <FormattedMessage id="MyWantedPostsPage.noResultsSubTitle" />
          </h3>
          {createWantedLink}
        </div>
      ) : null,
    [createWantedLink, listingsAreLoaded, pagination]
  );

  const heading = useMemo(
    () =>
      listingsAreLoaded && pagination.totalItems > 0 ? (
        <div>
          <h1 className={css.titleContainer}>
            <FormattedMessage
              id="MyWantedPostsPage.youHaveListings"
              values={{ count: pagination.totalItems }}
            />
          </h1>
          {createWantedLink}
        </div>
      ) : (
        noResults
      ),
    [createWantedLink, listingsAreLoaded, noResults, pagination]
  );

  const page = useMemo(() => (queryParams ? queryParams.page : 1), [queryParams]);
  const paginationLinks = useMemo(
    () =>
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="MyWantedPostsPage"
          pageSearchParams={{ page }}
          pagination={pagination}
        />
      ) : null,
    [listingsAreLoaded, page, pagination]
  );

  const closingErrorListingId = useMemo(
    () => !!closingListingError && closingListingError.listingId,
    [closingListingError]
  );
  const openingErrorListingId = useMemo(
    () => !!openingListingError && openingListingError.listingId,
    [openingListingError]
  );
  const discardingErrorListingId = useMemo(
    () => !!discardingListingError && discardingListingError.listingId,
    [discardingListingError]
  );

  const title = useMemo(() => intl.formatMessage({ id: 'MyWantedPostsPage.title' }), [intl]);

  const tableHeaders = useMemo(
    () => [
      intl.formatMessage({ id: 'MyWantedPostsPage.listingTitle' }),
      intl.formatMessage({ id: 'MyWantedPostsPage.listingType' }),
      intl.formatMessage({ id: 'MyWantedPostsPage.listingDates' }),
      intl.formatMessage({ id: 'MyWantedPostsPage.listingPrice' }),
      intl.formatMessage({ id: 'MyWantedPostsPage.listingStatus' }),
    ],
    [intl]
  );

  useEffect(() => {
    if (currentUser && currentUser.id) {
      onGetMyWantedPosts({ sharetribeAuthorId: currentUser.id.uuid, $page: page });
    }
  }, [onGetMyWantedPosts, currentUser, page]);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="MyWantedPostsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.listingPanel}>
            {heading}
            <div className={css.wantedPosts}>
              {queryInProgress ? loadingResults : null}
              {queryListingsError ? queryError : null}
              {listings.length > 0 && (
                <table>
                  <thead className={css.header}>
                    <tr>
                      {tableHeaders.map(header => (
                        <th key={header}>{header}</th>
                      ))}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {listings.map(l => {
                      const { sharetribeId: id, title = '', price, state, publicData } = l;
                      const { borrowingType, startTime, endTime } = publicData || {};

                      const slug = createSlug(title);
                      const isClosed = state === LISTING_STATE_CLOSED;
                      const isDraft = state === LISTING_STATE_DRAFT;

                      const formattedPrice = price
                        ? formatMoney(intl, new Money(price.amount, price.currency), 0)
                        : {};

                      const hasError =
                        openingErrorListingId.uuid === id ||
                        closingErrorListingId.uuid === id ||
                        discardingErrorListingId.uuid === id;

                      const actionsInProgressListingId =
                        openingListing || closingListing || discardingListing;
                      const thisListingInProgress =
                        actionsInProgressListingId && actionsInProgressListingId.uuid === id;

                      const editListingLinkType = isDraft
                        ? LISTING_PAGE_PARAM_TYPE_DRAFT
                        : LISTING_PAGE_PARAM_TYPE_EDIT;

                      const maxPrice = pricingType[borrowingType]
                        ? intl.formatMessage(
                            { id: 'MyWantedPostsPage.maxPrice' },
                            {
                              price: formattedPrice,
                              type: intl.formatMessage({ id: pricingType[borrowingType] }),
                            }
                          )
                        : notAvailable;

                      const borrowingTypeMaybe = borrowingType
                        ? intl.formatMessage({ id: `MyWantedPostsPage.${borrowingType}` })
                        : notAvailable;

                      const startDate = startTime
                        ? formatDateForWantedPosts(intl, new Date(startTime))
                        : null;
                      const endDate = endTime
                        ? formatDateForWantedPosts(intl, new Date(endTime))
                        : null;

                      const dates = {
                        hourly: {
                          date: startDate ? startDate.date : notAvailable,
                          time:
                            startDate && endDate
                              ? `${startDate.time} - ${endDate.time}`
                              : notAvailable,
                        },
                        daily: {
                          date:
                            startDate && endDate
                              ? `${startDate.date} - ${endDate.date}`
                              : notAvailable,
                        },
                        weekly: {
                          date:
                            startDate && endDate
                              ? `${startDate.date} - ${endDate.date}`
                              : notAvailable,
                        },
                      };

                      return (
                        <React.Fragment key={id}>
                          <tr key={id} className={css.wantedPost}>
                            <td>
                              <div className={css.wantedPostTitle}>
                                {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
                              </div>
                            </td>
                            <td>
                              <div className={css.bubble}>{borrowingTypeMaybe}</div>
                            </td>
                            <td>
                              <div className={css.wantedPostDates}>
                                <div className={css.bubble}>
                                  {borrowingType ? dates[borrowingType].date : notAvailable}
                                </div>
                                {borrowingType && dates[borrowingType].time && (
                                  <div className={css.bubble}>
                                    {borrowingType ? dates[borrowingType].time : notAvailable}
                                  </div>
                                )}
                              </div>
                            </td>

                            <td>{maxPrice}</td>
                            <td className={css.capitalizeText}>{state}</td>
                            <td>
                              <div className={css.wantedPostActions}>
                                <NamedLink
                                  className={css.wantedPostStateButton}
                                  name="EditListingPage"
                                  params={{
                                    id,
                                    slug,
                                    type: editListingLinkType,
                                    tab: 'description',
                                  }}
                                >
                                  <FormattedMessage id="MyWantedPostsPage.edit" />
                                </NamedLink>
                                <button
                                  className={classNames(css.wantedPostStateButton, {
                                    [css.stateButtonRed]: !isClosed,
                                  })}
                                  disabled={!!actionsInProgressListingId}
                                  onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    if (!actionsInProgressListingId) {
                                      const listingId = new UUID(id);

                                      if (isDraft) onDiscardListing(listingId);
                                      else if (isClosed) onOpenListing(listingId);
                                      else onCloseListing(listingId);
                                    }
                                  }}
                                >
                                  {isClosed ? (
                                    <FormattedMessage id="MyWantedPostsPage.open" />
                                  ) : (
                                    <FormattedMessage id="MyWantedPostsPage.close" />
                                  )}
                                </button>
                              </div>
                            </td>

                            {thisListingInProgress ? (
                              <Overlay>
                                <IconSpinner />
                              </Overlay>
                            ) : hasError ? (
                              <Overlay
                                errorMessage={intl.formatMessage({
                                  id: 'ManageListingCard.actionFailed',
                                })}
                                errorMessageClassName={css.overlayError}
                              />
                            ) : null}
                          </tr>
                          <tr className={css.spacing}>
                            <td colSpan="5" />
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            {paginationLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <LandingFooter isAuthenticated={isAuthenticated} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

MyWantedPostsPageComponent.defaultProps = {
  isAuthenticated: false,
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
  discardingListing: null,
  discardingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

MyWantedPostsPageComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  onCreateNewListing: func.isRequired,
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(object),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  discardingListing: shape({ uuid: string.isRequired }),
  discardingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  const {
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    discardingListing,
    discardingListingError,
  } = state.MyWantedPostsPage;

  const { currentUser } = state.user;

  return {
    isAuthenticated,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    discardingListing,
    discardingListingError,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onGetMyWantedPosts: params => dispatch(manualLoadData(params)),
  onCreateNewListing: data => dispatch(requestCreateListingDraft(data)),
  onDiscardListing: listingId => dispatch(discardListing(listingId)),
});

const MyWantedPostsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  injectIntl
)(MyWantedPostsPageComponent);

export default MyWantedPostsPage;
